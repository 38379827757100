import {debounce} from "lodash";

import MonacoLoader from "./monaco.js";

// export load methods, so client (jsonpicker) may asynchronous load it (maz)
const loadMonaco = MonacoLoader.ensureMonacoIsLoaded;
export {loadMonaco};

// inject JSON context to improve autocomplete
const injectContext = async (monaco, obj) => {
  if (monaco.__HIObjects) return;

  // TODO: Fix ES5/6 known errors (marco zoqui)
  // https://github.com/microsoft/TypeScript/blob/main/lib/lib.es5.d.ts
  // https://github.com/Microsoft/monaco-editor/issues/61
  // https://github.com/microsoft/monaco-editor/issues/1001

  // Disable default autocompletion for javascript
  // monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
  //   noLib: true
  // });
  // monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
  //   target: monaco.languages.typescript.ScriptTarget.ES2015,
  //   noLib: true,
  //   allowNonTsExtensions: true
  // });
  // monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
  //   // target: monaco.languages.typescript.ScriptTarget.ES2015,
  //   noLib: false,
  //   lib: ["es5", "es6"]
  // });

  // Helper function to return the monaco completion item type of a thing
  function getType(thing, isMember) {
    isMember =
      isMember == undefined
        ? typeof isMember == "boolean"
          ? isMember
          : false
        : false; // Give isMember a default value of false

    switch ((typeof thing).toLowerCase()) {
      case "object":
        return monaco.languages.CompletionItemKind.Class;

      case "function":
        return isMember
          ? monaco.languages.CompletionItemKind.Method
          : monaco.languages.CompletionItemKind.Function;

      default:
        return isMember
          ? monaco.languages.CompletionItemKind.Property
          : monaco.languages.CompletionItemKind.Variable;
    }
  }

  // Register object that will return autocomplete items
  monaco.languages.registerCompletionItemProvider("javascript", {
    // Run this function when the period or open parenthesis is typed (and anything after a space)
    triggerCharacters: [".", "("],

    // Function to generate autocompletion results
    provideCompletionItems: function(model, position, token) {
      // console.log(token);
      // Split everything the user has typed on the current line up at each space, and only look at the last word
      var last_chars = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 0,
        endLineNumber: position.lineNumber,
        endColumn: position.column
      });
      var words = last_chars.replace("\t", "").split(" ");
      var active_typing = words[words.length - 1]; // What the user is currently typing (everything after the last space)

      // This if statement adds support for autocomplete inside if statements and stuff
      if (active_typing.includes("(")) {
        active_typing = active_typing.split("(");
        active_typing = active_typing[active_typing.length - 1];
      }

      // If the last character typed is a period then we need to look at member objects of the obj object
      var is_member = active_typing.charAt(active_typing.length - 1) == ".";

      // Array of autocompletion results
      var result = [];

      // Used for generic handling between member and non-member objects
      var last_token = obj;
      var prefix = "";

      if (is_member) {
        // Is a member, get a list of all members, and the prefix
        var parents = active_typing
          .substring(0, active_typing.length - 1)
          .split(".");
        last_token = obj[parents[0]];
        prefix = parents[0];

        // Loop through all the parents the current one will have (to generate prefix)
        for (var i = 1; i < parents.length; i++) {
          var propToLookFor = parents[i];

          // Support for arrays
          var isPropAnArray =
            propToLookFor.charAt(propToLookFor.length - 1) == "]";
          if (isPropAnArray) propToLookFor = propToLookFor.split("[")[0];

          if (Object.prototype.hasOwnProperty.call(last_token, propToLookFor)) {
            prefix += "." + propToLookFor;
            last_token = last_token[propToLookFor];

            if (isPropAnArray && Array.isArray(last_token)) {
              last_token = last_token[0];
            }
          } else {
            // Not valid
            return result;
          }
        }

        prefix += ".";
      }

      // Array properties
      if (Array.isArray(last_token)) last_token = {length: 0};

      // Get all the child properties of the last token
      for (var prop in last_token) {
        // Do not show properites that begin with "__"
        if (
          Object.prototype.hasOwnProperty.call(last_token, prop) &&
          !prop.startsWith("__")
        ) {
          // Get the detail type (try-catch) incase object does not have prototype
          var details = "";
          try {
            details = last_token[prop].__proto__.constructor.name;
          } catch (e) {
            details = typeof last_token[prop];
          }

          // Create completion object
          var to_push = {
            label: prefix + prop,
            kind: getType(last_token[prop], is_member),
            detail: details,
            insertText: prop
          };

          // Change insertText and documentation for functions
          if (to_push.detail.toLowerCase() == "function") {
            to_push.insertText += "(";
            to_push.documentation = last_token[prop].toString().split("{")[0]; // Show function prototype in the documentation popup
          }

          // Add to final results
          result.push(to_push);
        }
      }

      return {
        suggestions: result
      };
    }
  });
  monaco.__HIObjects = true;
};

// TODO: get it from user profile/contract or config
const editorSettings = {
  theme: "vs",
  options: {
    minimap: {
      enabled: false
    },
    scrollbar: {
      vertical: "hidden",
      horizontal: "hidden",
      handleMouseWheel: false
    },
    overviewRulerLanes: 0,
    wordWrap: "on",
    automaticLayout: true,
    lineNumbers: false,
    glyphMargin: false,
    folding: false,
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 0
    // quickSuggestions: {
    //   other: false,
    //   comments: false,
    //   strings: false
    // },
    // parameterHints: {
    //   enabled: false
    // },
    // suggestOnTriggerCharacters: false,
    // acceptSuggestionOnEnter: "off",
    // tabCompletion: "off",
    // wordBasedSuggestions: false
  }
};

export default {
  name: "JSEditor",
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    enviroment: {
      type: Object,
      required: false,
      default: () => null
    },
    language: {
      type: String,
      default: "javascript"
    }
  },
  data() {
    return {
      ...{icode: ""},
      ...editorSettings
    };
  },
  computed: {
    code: {
      set(n) {
        this.icode = n;
        this.updateParent();
      },
      get() {
        return this.icode;
      }
    }
  },
  methods: {
    async init() {
      try {
        await loadMonaco();
        this.initMonaco();
      } catch (e) {
        console.error("Monaco could not be loaded. ", e);
      }
    },
    initMonaco() {
      const options = {
        value: this.code,
        theme: this.theme,
        language: this.language,
        ...this.options
      };
      this.editor = window.monaco.editor.create(this.$el, options);
      // inject global object from context
      if (this.language == "javascript") {
        if (typeof (this?.enviroment?.context || null) == "function") {
          this.enviroment.context(this).then((ctx) => {
            injectContext(window.monaco, ctx);
          });
        }
      }
      this.$emit("ready");

      this.editor.onDidChangeModelContent(() => {
        const code = this.editor.getValue();
        if (this.code !== code) {
          this.code = code;
        }
      });

      this.editor.addCommand(
        window.monaco.KeyMod.CtrlCmd | window.monaco.KeyCode.KeyS,
        () => {
          this.$emit("save");
        }
      );
      /*editor.addAction({
	// An unique identifier of the contributed action.
	id: 'my-unique-id',

	// A label of the action that will be presented to the user.
	label: 'My Label!!!',

	// An optional array of keybindings for the action.
	keybindings: [
		monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
		// chord
		monaco.KeyMod.chord(
			monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyK,
			monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyM
		)
	],

	// A precondition for this action.
	precondition: null,

	// A rule to evaluate on top of the precondition in order to dispatch the keybindings.
	keybindingContext: null,

	contextMenuGroupId: 'navigation',

	contextMenuOrder: 1.5,

	// Method that will be executed when the action is triggered.
	// @param editor The editor instance is passed in as a convenience
	run: function (ed) {
		alert("i'm running => " + ed.getPosition());
	}
});
      */
    }
  },
  created() {
    if (typeof (this?.enviroment?.iconv || null) == "function") {
      this.icode = this.enviroment.iconv(this, this.value);
    } else {
      this.icode = this.value;
    }
    this.updateParent = debounce(() => {
      if (typeof (this?.enviroment?.oconv || null) == "function") {
        this.$emit(
          "input",
          this.enviroment.oconv(this, this.icode, this.value)
        );
      } else {
        this.$emit("input", this.icode);
      }
    }, 500);
  },
  async mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.dispose();
    }
  },
  render: (h) => h("div")
};
