<template>
  <TogglePanel
    :title="labelSelection"
    :icon="{
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }"
    :collapsed="collapsed"
  >
    <slot name="header"></slot>
    <slot v-if="!panelDataOptions.length && !dataSelector" name="info">
      <div class="info">
        <div>{{ $t("connector_standard") }}</div>
      </div>
    </slot>
    <div class="data-selector-container" v-if="dataSelectionOnTop">
      <div
        class="text-center"
        v-if="!dataSelector && (showAddNewDataButton || showAddAllDataButton)"
      >
        <span
          class="btn btn-xs btn-primary"
          @click="dataSelector = true"
          v-if="showAddNewDataButton"
        >
          {{ $t($attrs.addNewText || "add_new_data") }}
        </span>
        <span
          class="btn btn-xs btn-default"
          @click.stop.prevent="$emit('all')"
          style="margin-left: 5px"
          v-if="showAddAllDataButton"
        >
          {{ $t("titles.add_all_data") }}
        </span>
      </div>
      <template v-if="dataSelector">
        <ControlDataSelector
          v-if="referenceConnectorId"
          ref="dataSelector"
          v-model="dataId"
          :parser="listParser"
          :connectorId="referenceConnectorId"
          :multiConnector="referenceConnectorId ? multiConnector : true"
          :exclude="allowDupplicated ? [] : dataListId"
          :connectorFilter="connectorFilter"
          :allowedTypes="['bool', 'float', 'int', 'string']"
          @connectorChanged="connectorId = $event"
        >
          <template #label>
            <div
              class="data-selector-label clicable"
              @click.stop.prevent="dataSelector = false"
            >
              <label for="">{{ $t("data_source") }}</label>
              <span
                class="clicable"
                @click.stop.prevent="modelOnly = !modelOnly"
                style="margin-left: 20px"
              >
                <i
                  :class="modelOnly ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                ></i>
                {{ $t("models") }}
              </span>
              <div class="toolbar-item">
                <DataDisplayLabelToggle />
                <i class="fa fa-close" :title="$t('remove_this_item')"></i>
              </div>
            </div>
          </template>
        </ControlDataSelector>
        <ControlDataSelector
          v-else
          ref="dataSelector"
          v-model="dataId"
          :parser="listParser"
          :exclude="dataListId"
          :connectorFilter="connectorFilter"
          :allowedTypes="['bool', 'float', 'int', 'string']"
          @connectorChanged="connectorId = $event"
        >
          <template #label>
            <div
              class="data-selector-label clicable"
              @click.stop.prevent="dataSelector = false"
            >
              <label for="">{{ $t("data_source") }}</label>
              <span
                class="clicable"
                @click.stop.prevent="modelOnly = !modelOnly"
                style="margin-left: 20px"
              >
                <i
                  :class="modelOnly ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                ></i>
                {{ $t("models") }}
              </span>
              <div class="toolbar-item">
                <DataDisplayLabelToggle />
                <i class="fa fa-close" :title="$t('remove_this_item')"></i>
              </div>
            </div>
          </template>
        </ControlDataSelector>
        <div class="text-center import-options">
          <span
            class="btn btn-xs btn-primary"
            :class="dataSelectionOnly && !dataId ? 'disabled' : ''"
            @click.stop.prevent="onAddData"
          >
            {{ $tc("titles.add_selected", 1) }}
          </span>
          <span
            class="btn btn-xs btn-default"
            @click.stop.prevent="onImport($event)"
            v-if="showAddAllDataButton && dataSelectionOnly && connector"
          >
            {{ $t("titles.add_all_data") }}
          </span>
        </div>
      </template>
    </div>
    <div class="data-list" v-if="panelDataOptions.length">
      <label style="width: 100%">
        <DataDisplayLabelToggle />
        {{ $t(labelItems) }}
        <span
          class="pull-right clicable"
          style="margin-right: 12px"
          @click.stop.prevent="removeDataList"
          :title="
            `${$t('remove_selected_ones')}: ${
              this.connector ? this.connector.name : $tc('all', 1)
            }\nCtrl+Click ${$tc('hints.remove_all', 1)}`
          "
        >
          <i class="fa fa-trash"></i>
        </span>
      </label>
      <draggable
        v-model="panelDataOptions"
        handle=".handle"
        :disabled="!draggable"
      >
        <div
          v-for="(item, ix) in panelDataOptions"
          :key="`${item.data_id}-${ix}`"
        >
          <div class="item handle">
            <div class="toolbar-item small">
              <i
                class="fa fa-trash clicable"
                :title="$t('remove_this_item')"
                @click.stop.prevent="onDelItemAt(ix)"
              ></i>
              <i
                v-if="collapsableItems"
                :title="`${$t('expand')}/${$t('collapse')}`"
                :class="
                  isItemCollapsed(ix)
                    ? 'fa fa-angle-right clicable'
                    : 'fa fa-angle-down clicable'
                "
                @click.stop.prevent="toggleItem(ix)"
              ></i>
            </div>
            <div class="item-title clicable" :title="itemTitle(item.data_id)">
              <div @click.stop.prevent="onCheckData(ix)">
                <i
                  class="glyphicon glyphicon-option-vertical"
                  v-if="draggable"
                ></i>
                <i
                  v-if="selectable"
                  :class="
                    item.checked
                      ? 'fa fa-check-square-o clicable'
                      : 'fa fa-square-o clicable'
                  "
                  style="vertical-align: middle"
                  :title="$t('titles.select_data')"
                ></i>
                <span>
                  {{
                    item.columnName ||
                      item[$store.getters["dashboard/dataDisplayLabel"]] ||
                      item.name
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- BEGIN of array value data index -->
          <ValueSourceSelector
            v-if="item.data_id == dataId && dataValueIndex"
            class="value-source-selector"
            v-model="dataValueIndex"
            :connectorId="currentDataConnector"
            :exclude="dataId ? [dataId] : []"
            :disabled="!dataValueIndex.enabled"
          >
            <template #label>
              <div>
                <label
                  class="clicable no-select"
                  for="data_value_index"
                  @click.stop.prevent="toggleDataValueIndex"
                >
                  <i
                    :class="
                      dataValueIndex.enabled
                        ? 'fa fa-check-square-o'
                        : 'fa fa-square-o'
                    "
                  />
                  {{ $t("titles.data_value_index") }}
                  <ToolTip
                    :title="
                      `${$t('hints.data_value_index')}<br/>${$tc(
                        'interval',
                        1
                      )}: &ge; 0 &amp;&amp; &le; ${memorySize - 1}`
                    "
                  />
                </label>
                <div class="pull-right">
                  <span class="small text-primary">
                    &ge; 0 &amp;&amp; &le;
                    {{ memorySize - 1 }}
                    &nbsp;&nbsp;
                  </span>
                </div>
              </div>
            </template>
          </ValueSourceSelector>
          <!-- END of array value data index -->
          <div :style="{display: isItemCollapsed(ix) ? 'none' : ''}">
            <slot name="item" :item="item"></slot>
          </div>
        </div>
      </draggable>
    </div>
    <div
      class="data-selector-container"
      v-if="!dataSelectionOnTop"
      :style="
        panelDataOptions.length
          ? 'margin-top:20px;padding-top: 5px; border-top: 2px solid #367fa9'
          : ''
      "
    >
      <div
        class="text-center"
        v-if="!dataSelector && (showAddNewDataButton || showAddAllDataButton)"
      >
        <span
          class="btn btn-xs btn-primary"
          @click="dataSelector = true"
          v-if="showAddNewDataButton"
        >
          {{ $t($attrs.addNewText || "add_new_data") }}
        </span>
        <span
          class="btn btn-xs btn-default"
          @click.stop.prevent="$emit('all')"
          style="margin-left: 5px"
          v-if="showAddAllDataButton"
        >
          {{ $t("titles.add_all_data") }}
        </span>
      </div>
      <template v-if="dataSelector">
        <ControlDataSelector
          v-if="referenceConnectorId"
          ref="dataSelector"
          v-model="dataId"
          :parser="listParser"
          :connectorId="referenceConnectorId"
          :multiConnector="referenceConnectorId ? multiConnector : true"
          :exclude="allowDupplicated ? [] : dataListId"
          :connectorFilter="connectorFilter"
          :allowedTypes="['bool', 'float', 'int', 'string']"
          @connectorChanged="connectorId = $event"
        >
          <template #label>
            <div
              class="data-selector-label clicable"
              @click.stop.prevent="dataSelector = false"
            >
              <label for="">{{ $t("data_source") }}</label>
              <span
                class="clicable"
                @click.stop.prevent="modelOnly = !modelOnly"
                style="margin-left: 20px"
              >
                <i
                  :class="modelOnly ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                ></i>
                {{ $t("models") }}
              </span>
              <div class="toolbar-item">
                <DataDisplayLabelToggle />
                <i class="fa fa-close" :title="$t('remove_this_item')"></i>
              </div>
            </div>
          </template>
        </ControlDataSelector>
        <ControlDataSelector
          v-else
          ref="dataSelector"
          v-model="dataId"
          :parser="listParser"
          :exclude="dataListId"
          :connectorFilter="connectorFilter"
          :allowedTypes="['bool', 'float', 'int', 'string']"
          @connectorChanged="connectorId = $event"
        >
          <template #label>
            <div
              class="data-selector-label clicable"
              @click.stop.prevent="dataSelector = false"
            >
              <label for="">{{ $t("data_source") }}</label>
              <span
                class="clicable"
                @click.stop.prevent="modelOnly = !modelOnly"
                style="margin-left: 20px"
              >
                <i
                  :class="modelOnly ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                ></i>
                {{ $t("models") }}
              </span>
              <div class="toolbar-item">
                <DataDisplayLabelToggle />
                <i class="fa fa-close" :title="$t('remove_this_item')"></i>
              </div>
            </div>
          </template>
        </ControlDataSelector>
        <div class="text-center import-options">
          <span
            class="btn btn-xs btn-primary"
            :class="dataSelectionOnly && !dataId ? 'disabled' : ''"
            @click.stop.prevent="onAddData"
          >
            {{ $tc("titles.add_selected", 1) }}
          </span>
          <span
            class="btn btn-xs btn-default"
            @click.stop.prevent="onImport($event)"
            v-if="showAddAllDataButton && dataSelectionOnly && connector"
          >
            {{ $t("titles.add_all_data") }}
          </span>
        </div>
      </template>
    </div>
    <slot name="footer"></slot>
  </TogglePanel>
</template>

<script>
import {isEqual} from "lodash";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import draggable from "vuedraggable";
import ValueSourceSelector from "@/components/editor/value-source-selector.vue";
import ToolTip from "@/components/tooltip.vue";
import {initialValue as DftDataValueIndex} from "@/components/editor/value-source-selector.vue";
import DataDisplayLabelToggle from "@/components/editor/data-display-label-toggle.vue";

export default {
  name: "DataListForm",
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    dataListParser: {
      type: Function,
      required: false,
      default: null
    },
    connectorFilter: {
      type: [Function],
      required: false,
      default: null
    },
    selectable: {
      type: Boolean,
      required: false,
      default: true
    },
    labelSelection: {
      type: String,
      required: false,
      default: "data_list"
    },
    labelItems: {
      type: String,
      required: false,
      default: "data_series"
    },
    dataSelectionOnly: {
      type: Boolean,
      required: false,
      default: () => true
    },
    multiConnector: {
      type: Boolean,
      required: false,
      default: true
    },
    showAddNewDataButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showAddAllDataButton: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: true
    },
    itemsCollapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    allowDupplicated: {
      type: Boolean,
      required: false,
      default: false
    },
    draggable: {
      type: Boolean,
      required: false,
      default: true
    },
    dataSelectionOnTop: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    TogglePanel,
    ControlDataSelector,
    draggable,
    ValueSourceSelector,
    ToolTip,
    DataDisplayLabelToggle
  },
  data() {
    return {
      dataId: "",
      connectorId: "",
      items: [],
      dataSelector: false,
      iModelOnly: false,
      collapsedItems: null
    };
  },
  computed: {
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    dataListId() {
      return this.items.map(({data_id}) => data_id);
    },
    panelDataOptions: {
      set(values) {
        this.items = [];
        (values || []).forEach((item, ix) => this.$set(this.items, ix, item));
      },
      get() {
        return (this.items || []).map((item) => {
          var entry = this.dataList.find(({id}) => id == item.data_id);
          if (!entry) {
            if (/connector_\d+_id/.test(item.data_id)) {
              var conn_id = item.data_id.match(/\d+/)[0];
              entry = this.connectorList.find(({id}) => id == conn_id);
            }
          }
          return {
            ...item,
            ...{
              name: (entry && entry.name) || "",
              identity_embedded_app:
                (entry && entry.identity_embedded_app) || ""
            }
          };
        });
      }
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    referenceConnectorId() {
      return (
        (this.$store.getters["dashboard/screenRefMap"](this.screenId) || {})
          ?.conn1 || ""
      );
    },
    connector() {
      const ID = this.connectorId || this.refConnectorId || "";
      return ID ? this.connectorList.find(({id}) => id == ID) : null;
    },
    customHistoryDataList() {
      return this?.connector?.portal_data?.custom_history_data_list || {};
    },
    currentData() {
      return this.dataList.find(
        ({id}) => parseInt(id) == parseInt(this.dataId)
      );
    },
    currentDataConnector() {
      return this?.currentData?.clp_id;
    },
    memorySize() {
      return this?.currentData?.memory_size ?? undefined;
    },
    dataValueIndex: {
      set(val) {
        if (!this.currentData || !(this.memorySize > 1) || !val) return;
        let option = this.items.find(
          ({data_id}) => parseInt(data_id) == parseInt(this.dataId)
        );
        if (!option) return;
        let entry = {...val};
        if (entry.type == "constant") {
          entry.value =
            entry.value !== "" && !isNaN(Number(entry.value))
              ? parseInt(entry.value)
              : -1;
          if (entry.value < 0) entry.value = -1;
          else if (entry.value >= this.memorySize)
            entry.value = this.memorySize - 1;
        }
        this.$set(option, "data_value_index", entry);
      },
      get() {
        if (!this.currentData || !(this.memorySize > 1)) return null;
        let option = this.panelDataOptions.find(
          ({data_id}) => parseInt(data_id) == parseInt(this.dataId)
        );
        if (!option) return null;
        let entry = option.data_value_index || DftDataValueIndex();
        return entry;
      }
    },
    modelOnly: {
      set(value) {
        this.iModelOnly = value;
        if (this.$refs.dataSelector) {
          this.$refs.dataSelector.modelOnly = this.iModelOnly;
        }
      },
      get() {
        return this.iModelOnly;
      }
    },
    collapsableItems() {
      return this.collapsedItems !== null;
    }
  },
  watch: {
    value: {
      handler(n) {
        if (isEqual(n, this.items)) return;
        this.$set(this, "items", JSON.parse(JSON.stringify(n)));
      },
      deep: true,
      immediate: true
    },
    items: {
      handler(n) {
        if (isEqual(n, this.value)) return;
        this.$emit("input", n);
      },
      deep: true
    }
  },
  methods: {
    listParser(lst) {
      return this.dataListParser ? this.dataListParser(lst) : lst;
    },
    addData(entry) {
      if (!entry.data_id) return;
      let lst = JSON.parse(JSON.stringify(this.items || []));
      lst = this.allowDupplicated
        ? lst
        : lst.filter(({data_id}) => entry.data_id != data_id);
      lst.push({...entry, index: lst.length});
      this.$set(this, "items", lst);
    },
    onAddData() {
      if (!this.connectorId || (this.dataSelectionOnly && !this.dataId)) return;
      if (this?.dataId) {
        // remove any connector based item, since data based list was selected
        let data = this.dataList.find(({id}) => id == this.dataId);
        this.onDelDataById(`connector_${data.clp_id}_id`);
      } else {
        // remove any data based item, since connector based list was selected
        this.dataList
          .filter(({clp_id}) => clp_id == this.connectorId)
          .forEach(({id}) => {
            this.onDelDataById(id);
          });
      }
      this.addData({
        data_id: this?.dataId || `connector_${this.connectorId}_id`,
        enabled: true,
        checked: true
      });
      if (this.items.length) {
        if (this.itemsCollapsed) {
          this.collapseAllItems();
          this.toggleItem(this.items.length - 1);
        }
        this.$emit("item:added", this.items[this.items.length - 1]);
      }
    },
    onDelDataById(dataId) {
      if (!dataId) return;
      let lst = JSON.parse(JSON.stringify(this.items || []));
      lst = lst.filter(({data_id}) => data_id != dataId);
      this.$set(this, "items", lst);
    },
    onDelItemAt(ix) {
      if (ix < 0 || ix > this.items.length - 1) return;
      let item = this.items.splice(ix, 1)[0];
      if (item.index !== undefined) {
        this.items.map((i, ix) => {
          i.index = ix;
          return i;
        });
      }
      this.$set(this, "items", this.items);
    },
    setCurrentDataId(dataId) {
      this.dataId =
        !dataId || parseInt(this.dataId) == parseInt(dataId)
          ? ""
          : parseInt(dataId);
    },
    onCheckData(ix) {
      if (ix < 0 || ix > this.items.length - 1) return;
      if (!this.selectable && this.collapsableItems) {
        this.toggleItem(ix);
        return;
      }
      let dataId = this.items[ix].data_id;
      this.setCurrentDataId(dataId);
      if (!dataId || !this.selectable) return;
      let lst = JSON.parse(JSON.stringify(this.items || []));
      let item = lst.find(({data_id}) => parseInt(data_id) == parseInt(dataId));
      item.checked = !item.checked;
      this.$set(this, "items", lst);
    },
    onImport($event) {
      let lst = $event.ctrlKey
        ? this.dataList
        : this.connector
        ? this.dataList.filter(
            ({clp_id}) => parseInt(clp_id) == parseInt(this.connector.id)
          )
        : [];
      this.listParser(lst).forEach((data) => {
        this.addData({
          data_id: data.id,
          enabled: true,
          checked: this.selectable
            ? (this.customHistoryDataList?.checked || []).indexOf(
                data.reference_id
              ) >= 0
            : false
        });
      });
    },
    itemTitle(dataId) {
      let data = this.dataList.find(({id}) => dataId == id);
      if (!data) return "";
      let conn = this.$store.getters["dashboard/connectorList"].find(
        ({id}) => id == data.clp_id
      );
      if (data.identity_embedded_app) {
        return `#id: ${data.id} - ${this.$tc("connector", 1)}: ${(conn &&
          conn.name) ||
          this.$t("item_not_found")} - ${this.$t(
          "titles.identity_embedded_app"
        )}: ${data.identity_embedded_app}`;
      }
      return `#id: ${data.id} - ${this.$tc("connector", 1)}: ${(conn &&
        conn.name) ||
        this.$t("item_not_found")}`;
    },
    toggleDataValueIndex() {
      if (!this.currentData || !(this.memorySize > 1)) return;
      let option = this.items.find(
        ({data_id}) => parseInt(data_id) == parseInt(this.dataId)
      );
      if (!option) return;
      let entry = {...(option.data_value_index || DftDataValueIndex())};
      entry.enabled = !entry.enabled;
      if (entry.enabled) {
        entry.type = "constant";
        entry.value = 0;
      } else {
        entry.type = "constant";
        entry.value = -1;
      }
      option.data_value_index = entry;
      this.$set(option, "data_value_index", entry);
    },
    removeDataList() {
      this.$utils
        .confirm(this, this.$t("you_wont_be_able_to_revert_this"))
        .then((ok) => {
          if (!ok) return;
          this.items = [];
        });
    },
    toggleItem(ix) {
      if (ix < 0 || ix > this.items.length - 1) return;
      this.$set(this.collapsedItems, ix, !this.isItemCollapsed(ix));
    },
    isItemCollapsed(ix) {
      return (this.collapsedItems || {})[ix] ?? false;
    },
    collapseAllItems() {
      if (!this?.items?.length) return;
      let entry = {};
      (this.items || []).forEach((a, i) => (entry[i] = true));
      this.$set(this, "collapsedItems", entry);
    }
  },
  mounted() {
    if (this.$scopedSlots.item) {
      if (this.itemsCollapsed) {
        this.collapseAllItems();
      } else {
        this.$set(this, "collapsedItems", {});
      }
    }
  }
};
</script>

<style scoped>
div.option {
  margin: 3px;
  white-space: nowrap;
}

div.option > i {
  margin-right: 3px;
}

.import-options {
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.import-options > span.btn {
  margin: 0 5px 0 0;
}

div.data-list {
  margin-top: 5px;
}

div.item {
  position: relative;
  width: 100%;
  font-size: 1em;
  border-top: 1px solid #e4e4e4;
  z-index: 1;
}

div.item-title {
  padding: 4px 22px 4px 0px;
  font-weight: 600;
  white-space: normal;
  width: 100%;
  line-height: 1.5em;
  padding-right: 50px;
}

div.item-title > div {
  width: 100%;
  overflow: hidden;
}

div.item-title > div > i.glyphicon-option-vertical {
  font-weight: 100;
  color: #888;
}

div.toolbar-item {
  position: absolute;
  top: 0.4em;
  right: 0.4em;
  z-index: 4;
}

div.toolbar-item > i {
  padding: 0 4px;
  min-width: 22px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.info {
  margin: 0 12px 0 12px;
  padding: 5px;
  height: auto;
}

.info > label {
  display: block;
  font-weight: normal;
  font-size: 12pt;
  padding-left: 2px;
}
.info > div {
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 14pt;
  text-align: center;
}

i.fa-arrows-v {
  margin-left: -10px;
  margin-right: 10px;
  color: #888;
}

.data-selector-container {
  padding: 0px;
}
.data-selector-label {
  clear: both;
  position: relative;
  margin-right: -10px;
}

.value-source-selector {
  margin: 2px -10px 10px -10px;
  background-color: whitesmoke;
  padding: 10px 10px 16px 10px;
  z-index: 999;
}
</style>
