<template>
  <div v-if="items && items.length">
    <div
      class="form-group-label clicable"
      @click.stop.prevent="adding = !adding"
    >
      <div v-if="adding">
        {{ $t("synoptic.events.select_an_action") }}
        <Tooltip
          :title="
            $t(
              'hints.select_the_type_and_actions_you_want_to_trigger_at_this_event'
            )
          "
        />
        <span class="pull-right" :title="$t('close')">
          <i class="fa fa-angle-up"></i>
        </span>
      </div>
      <div v-else>
        <div class="text-center add-button">
          <span class="btn btn-xs btn-primary">
            {{ $t("synoptic.events.add_an_action") }}
          </span>
        </div>
      </div>
    </div>
    <div class="form-group form-group-sm" v-if="adding">
      <div class="input-group">
        <div class="input-group-btn">
          <button
            type="button"
            class="btn dropdown-toggle input-group-btn-dropdown"
            data-toggle="dropdown"
            aria-expanded="false"
            style="position: relative; max-height: 30px"
          >
            <div class="input-group-label">
              {{ $tc("group") }}
            </div>
            {{ $t(`synoptic.events.actions.${selectedGroup}`) }}
            <span class="fa fa-caret-down"></span>
          </button>
          <ul class="dropdown-menu">
            <li v-for="(group, ix1) in groups" :key="ix1">
              <a href="#" @click.prevent="selectedGroup = group">{{
                $t(`synoptic.events.actions.${group}`)
              }}</a>
            </li>
          </ul>
        </div>
        <div style="width: 100%; position: relative">
          <div class="input-group-label">
            {{ $t("action") }}
          </div>
          <select
            v-model="selected"
            class="form-control select-sm"
            id="event_selection"
            style="font-size: 90%"
          >
            <option value="">
              {{ $t("synoptic.events.select_an_action") }}
            </option>
            <option
              :value="item.type"
              v-for="(item, ix) in filteredItems"
              :key="ix"
            >
              {{ $t(`synoptic.events.actions.${item.title}`) }}
            </option>
          </select>
        </div>
        <div
          class="input-group-addon btn btn-primary"
          :title="$t('synoptic.events.add_an_action')"
          @click.stop.prevent="add"
          :class="{disabled: !selected}"
        >
          <i class="fa fa-check"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Actions from "@/assets/dashboard/actions.json";
import ControlsSettings from "@/assets/dashboard/controls_settings.json";
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "ControlActionSelector",
  props: {
    control: {
      type: Object,
      required: true
    },
    eventName: {
      type: String,
      required: true
    },
    exclude: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  components: {
    Tooltip
  },
  data() {
    return {
      selectedGroup: "",
      selected: "",
      adding: false
    };
  },
  computed: {
    componentName() {
      return (
        this?.control?.synopticComponent?.componentName ||
        this?.control?.componentName ||
        ""
      );
    },
    filteredItems() {
      return this.items.filter(
        (i) => i.type.split(":")[0].indexOf(this.selectedGroup) >= 0
      );
    },
    controlActions() {
      return this.componentName
        ? ControlsSettings[this.componentName].actions
        : [];
    },
    items() {
      let lst = JSON.parse(JSON.stringify(Actions || []));
      if (this.exclude) {
        for (let i in this.exclude) {
          let ix = lst.findIndex((item) => item.type == this.exclude[i]);
          if (ix >= 0) {
            lst.splice(ix, 1);
          }
        }
      }
      return lst.filter((i) => {
        return (
          i.enabled &&
          i.valid_events.indexOf(this.eventName) >= 0 &&
          (this.controlActions.length
            ? this.controlActions.indexOf(i.type) >= 0
            : true)
        );
      });
    },
    groups() {
      let aSet = new Set((this.items || []).map((i) => i.type.split(":")[0]));
      // return Array.from(aSet).sort();
      return Array.from(aSet);
    }
  },
  watch: {
    selectedGroup(n) {
      if (n) {
        this.reset();
      }
    },
    items: {
      handler(n) {
        this.reset();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    reset() {
      this.selected = "";
      this.$nextTick(() => {
        this.adding = this?.filteredItems?.length ? true : false;
      });
    },
    add() {
      if (this.selected) {
        let action = Actions.find((action) => action.type == this.selected);
        if (action) {
          this.$emit("addAction", JSON.parse(JSON.stringify(action)));
          this.$nextTick(() => {
            this.reset();
          });
        }
      }
    }
  },
  mounted() {
    this.selectedGroup = this.groups.length ? this.groups[0] : "";
  }
};
</script>

<style scoped>
.select-sm {
  font-size: 12pt;
  padding: 0;
}
.select-title {
  font-weight: 600;
  color: rgb(128, 128, 128);
}
.btn-add-action {
  background-color: rgb(184 193 199);
  color: #1f1f1f;
}
.input-group-btn:focus {
  outline: none !important;
}
.input-group-btn:active {
  outline: none !important;
}
.input-group-btn > button:active {
  outline: none !important;
}
.input-group-btn-dropdown {
  line-height: 1.2;
  position: relative;
  background: white;
  border-width: 1px 0 1px 1px;
  border-color: lightgray;
  border-style: solid;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
.form-group {
  margin: 20px 0 10px 0;
}

.form-group-label {
  margin: 5px 0 0 0;
  /* font-weight: 600; */
}

.input-group-label {
  position: absolute;
  top: -14px;
  left: -3px;
  font-size: 80%;
  color: grey;
  z-index: 3;
  background: transparent;
  padding: 0px 4px;
}
.add-button {
  padding: 15px 0;
}
.input-group-addon.btn-primary {
  background-color: #3c8dbc;
  color: white;
}
</style>
